import { FSA } from 'flux-standard-action';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';
import { ADD_TOAST } from './action-types';
import { removeToast } from './actions';

const removeToastEpic = (action$) =>
  action$.pipe(
    ofType(ADD_TOAST),
    mergeMap((action: FSA<any, any>) =>
      of(action.payload).pipe(delay(action.payload.timeout))
    ),
    map((toast) => removeToast(toast))
  );

export default removeToastEpic;
