import { FSA } from 'flux-standard-action';
import {
  LOGIN_REQUESTED,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT,
  SET_PROMO_CODE,
} from './action-types';

import { decodeJWTPayload } from 'shared/utils/token';

type State = {
  token: string;
  tokenExp: number;
  tokenValidFrom: number;
  refreshToken: string;
  isRoot: boolean;
  status: string;
  userId: string;
  bookingIds: string[];
};

const initialState: State = {
  token: '',
  tokenExp: 0,
  tokenValidFrom: 0,
  refreshToken: '',
  isRoot: false,
  status: '',
  userId: '',
  bookingIds: [],
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case SET_PROMO_CODE:
      return { ...state, promoCode: action.payload };
    case LOGIN_REQUESTED:
      return { ...state, status: 'loading' };
    case LOGIN_SUCCEEDED: {
      try {
        const token = action.payload.access_token;
        const refreshToken = action.payload.refresh_token;
        const {
          sub: userId,
          exp: tokenExp,
          nbf: tokenValidFrom,
          admin: isRoot,
          booking_ids: bookingIds,
        } = decodeJWTPayload(token);

        return {
          ...state,
          status: 'done',
          token,
          tokenExp,
          tokenValidFrom,
          refreshToken,
          isRoot,
          userId,
          bookingIds,
        };
      } catch (e) {
        return state;
      }

      // const {sub: userId} = decodeJWTPayload(token);
    }
    case LOGIN_FAILED:
      return { ...state, status: 'failed' };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
