import { combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import toasterEpic from 'shared/toaster/redux/epics';
import globalsEpic from 'shared/redux/globals/epics';
import sessionEpic from 'shared/redux/session/epics';

const coreEpic = combineEpics(toasterEpic, globalsEpic, sessionEpic);

const epic$ = new BehaviorSubject(coreEpic);

const rootEpic = (action$, state$, dependencies): any =>
  epic$.pipe(
    mergeMap((epic: any) =>
      epic(action$, state$, dependencies).pipe(
        catchError((e, source) => {
          setTimeout(() => {
            throw e;
          }, 0);
          return source;
        })
      )
    )
  );

// Timeout to prevent next before behaviour subject has run
export const registerEpic = (e) => setTimeout(() => epic$.next(e));
export default rootEpic;
