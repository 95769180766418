import { FSA } from 'flux-standard-action';
import { combineEpics } from 'redux-observable';
import { filter, ignoreElements, map } from 'rxjs/operators';
import { USER_FETCH_SUCCEEDED } from '../users/action-types';

const userLoadedEpic = (action$, state$) =>
  action$.pipe(
    filter((a: FSA<any, any>) => [USER_FETCH_SUCCEEDED].includes(a.type)),
    map((a: FSA<any, any>) => {
      const state = state$.value;

      const user = state.users.data[state.session.userId];
      if (!user) {
        return;
      }

      const heap = window['heap'];

      if (heap) {
        heap.identify(user.id);
        heap.addUserProperties({
          Name: `${user.firstname} ${user.lastname}`,
          Email: user.primary_email,
        });
      }

      Sentry.getCurrentScope().setUser({
        email: user.primary_email,
        id: user.id,
        name: `${user.firstname} ${user.lastname}`,
        username: `${user.firstname} ${user.lastname}`,
        cellphone: user.primary_cellphone,
      });

      // Sentry.configureScope((scope) => {
      //   scope.setUser({
      //     email: user.primary_email,
      //     id: user.id,
      //     name: `${user.firstname} ${user.lastname}`,
      //     username: `${user.firstname} ${user.lastname}`,
      //     cellphone: user.primary_cellphone,
      //   });
      // });
    }),
    ignoreElements()
  );

export default combineEpics(userLoadedEpic);
