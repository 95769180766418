import { FSA } from 'flux-standard-action';
import {
  SMS_PACKAGES_FETCH_SUCCEEDED,
  SMS_PRICING_FETCH_SUCCEEDED,
} from './action-types';

const initialState = {
  smsPricing: {},
  exchangeRates: {},
  packageData: {
    host: '',
    packages: [],
  },
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  switch (action.type) {
    case SMS_PRICING_FETCH_SUCCEEDED:
      return {
        ...state,
        smsPricing: action.payload.pricing,
        exchangeRates: action.payload.exchange_rates,
      };

    case SMS_PACKAGES_FETCH_SUCCEEDED:
      return {
        ...state,
        packageData: action.payload,
      };

    default:
      return state;
  }
}
