import { FSA } from 'flux-standard-action';
import { ADD_TOAST, REMOVE_TOAST } from './action-types';

type State = {
  status: string;
  toasts: {
    body: string | React.ElementType<any>;
    className: string;
    timeout: number;
  }[];
};

const initialState: State = {
  status: '',
  toasts: [
    // {
    //   body: 'Test toast',
    //   className: 'alert',
    //   timeout: 5000,
    // },
    // {
    //   body: 'Test toast',
    //   className: 'warning',
    //   timeout: 5000,
    // },
    // {
    //   body: 'Test toast',
    //   className: 'success',
    //   timeout: 5000,
    // },
  ],
};

export default function toastsReducer(
  state = initialState,
  action: FSA<any, any>
) {
  switch (action.type) {
    case ADD_TOAST: {
      const lastToast = state.toasts[state.toasts.length - 1];
      // Prevent double toast
      if (
        lastToast &&
        lastToast.className === action.payload.className &&
        lastToast.body === action.payload.body
      ) {
        return state;
      }

      return {
        ...state,
        toasts: [action.payload, ...state.toasts],
      };
    }
    case REMOVE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter((t) => t !== action.payload),
      };
    default:
      return state;
  }
}
