import React from 'react';
// import { useStateWithRef } from '../hooks';
// import { iOS } from '../utils/detect';

interface ModalToastContextInterface {
  modalCount: number;
  incrementModalCount: () => void;
  decrementModalCount: () => void;
}

export const ModalToastContext =
  React.createContext<ModalToastContextInterface | null>({
    modalCount: 0,
    incrementModalCount: () => null,
    decrementModalCount: () => null,
  });

// const isIOS = true; //iOS();

export const ModalToastProvider = ({ children }) => {
  const [modalCount, setModalCount] = React.useState(0);

  // const savedScroll = React.useRef(0);

  const incrementModalCount = React.useCallback(() => {
    // Background scroll prevention?
    // if (modalCountRef.current === 0 && isIOS) {
    //   const root = document.querySelector('#root') as any;
    //   if (root) {
    //     savedScroll.current = window.document.documentElement.scrollTop;

    //     root.style.position = 'fixed';
    //     root.style.width = '100%';
    //   }
    // }

    setModalCount((v) => v + 1);
  }, [setModalCount]);

  const decrementModalCount = React.useCallback(() => {
    // if (modalCountRef.current == 0 && isIOS) {
    //   const root = document.querySelector('#root') as any;
    //   if (root) {
    //     root.style.position = '';
    //     root.style.width = '';

    //     window.document.documentElement.scrollTo(0, savedScroll.current);
    //   }
    // }

    setModalCount((v) => v - 1);
  }, [setModalCount]);

  const value = React.useMemo(
    () => ({
      modalCount,
      incrementModalCount,
      decrementModalCount,
    }),
    [decrementModalCount, incrementModalCount, modalCount]
  );

  return (
    <ModalToastContext.Provider value={value}>
      {children}
    </ModalToastContext.Provider>
  );
};
