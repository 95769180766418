export const USER_FETCH_REQUESTED = 'USER_FETCH_REQUESTED';
export const USER_FETCH_SUCCEEDED = 'USER_FETCH_SUCCEEDED';
export const USER_FETCH_FAILED = 'USER_FETCH_FAILED';

export const USER_SAVE_REQUEST = 'USER_SAVE_REQUEST';
export const USER_SAVE_SUCCEEDED = 'USER_SAVE_SUCCEEDED';
export const USER_SAVE_FAILED = 'USER_SAVE_FAILED';

export const USER_DELETE_REQUESTED = 'USER_DELETE_REQUESTED';
export const USER_DELETE_SUCCEEDED = 'USER_DELETE_SUCCEEDED';
export const USER_DELETE_FAILED = 'USER_DELETE_FAILED';

export const PASSWORD_CHANGE_REQUESTED = 'PASSWORD_CHANGE_REQUESTED';
export const PASSWORD_CHANGE_COMPLETED = 'PASSWORD_CHANGE_COMPLETED';
export const PASSWORD_CHANGE_FAILED = 'PASSWORD_CHANGE_FAILED';
