import { Theme } from '@emotion/react';
import base from 'shared/theme';

const theme: Theme = {
  ...base,
  // maxGridWidth: '1440px',
  maxGridWidth: '1200px',
};

export default theme;
