import React from 'react';
import { Box } from './grid';
import { Heading, Link } from './typography';

const components = {
  p: function P({ children, ...props }) {
    return (
      <Box mb={'1rem'} as="p" {...props}>
        {children}
      </Box>
    );
  },
  h2: function H2({ children, ...props }) {
    return (
      <Heading as="h2" mb={'1rem'} {...props}>
        {children}
      </Heading>
    );
  },
  h3: function H3({ children, ...props }) {
    return (
      <Heading as="h2" mb={'1rem'} {...props}>
        {children}
      </Heading>
    );
  },
  a: function A({ children, ...props }) {
    return <Link {...props}>{children}</Link>;
  },
  ul: function UL({ children, ...props }) {
    return (
      <Box ml={3} as="ul" mb={'1rem'} {...props}>
        {children}
      </Box>
    );
  },
  ol: function OL({ children, ...props }) {
    return (
      <Box ml={3} as="ol" mb={'1rem'} {...props}>
        {children}
      </Box>
    );
  },
};

export default components;
