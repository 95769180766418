import settings from '../settings';
import axios from '../anaxios';
import { FSA } from 'flux-standard-action';

export type state = {
  status: string;
  data: { [key: string]: any };
};

export const buildStandardReducer = (actionPrefix) => {
  const initialState: state = {
    status: '',
    data: {},
  };

  return function reducer(state = initialState, action: FSA<any, any>) {
    switch (action.type) {
      case `${actionPrefix}_FETCH_REQUESTED`:
        return { ...state, status: 'loading' };
      case `${actionPrefix}_FETCH_SUCCEEDED`: {
        return {
          ...state,
          status: 'done',
          data: {
            ...state.data,
            ...action.payload,
          },
        };
      }
      case `${actionPrefix}_FETCH_FAILED`:
        return { ...state, status: 'failed' };
      case `${actionPrefix}_SAVE_REQUESTED`:
        return { ...state, status: 'saving' };
      case `${actionPrefix}_SAVE_SUCCEEDED`: {
        return {
          ...state,
          status: 'done',
          data: {
            ...state.data,
            [action.payload.id]: action.payload,
          },
        };
      }
      case `${actionPrefix}_SAVE_FAILED`:
        return { ...state, status: 'failed' };
      case `${actionPrefix}_PATCH_REQUESTED`:
        return { ...state, status: 'saving' };
      case `${actionPrefix}_PATCH_SUCCEEDED`: {
        return {
          ...state,
          status: 'done',
          data: {
            ...state.data,
            [action.payload.id]: action.payload,
          },
        };
      }
      case `${actionPrefix}_PATCH_FAILED`:
        return { ...state, status: 'failed' };
      case `${actionPrefix}_DELETE_REQUESTED`:
        return { ...state, status: 'deleting' };
      case `${actionPrefix}_DELETE_SUCCEEDED`: {
        const { [action.payload]: deleted, ...nonDeleted } = state.data;
        return {
          ...state,
          status: 'done',
          data: {
            ...nonDeleted,
            [deleted.id]: { ...deleted, is_deleted: true },
          },
        };
      }
      case `${actionPrefix}_DELETE_FAILED`:
        return { ...state, status: 'failed' };
      default:
        return state;
    }
  };
};

export const buildStandardActions = (
  endpoint,
  actionPrefix,
  authenticated = true
) => {
  const root = `${settings.api2Root}/${endpoint}`;

  // console.log({ root });

  const addAuthHeaders = (otherHeaders, getState, authenticated) => {
    const token = getState().session.token;
    const headers = authenticated
      ? { ...otherHeaders, Authorization: `Bearer ${token}` }
      : otherHeaders;
    return headers;
  };

  const fetchEntity = (entityId) => {
    return (dispatch, getState) => {
      const headers = addAuthHeaders({}, getState, authenticated);

      dispatch({ type: `${actionPrefix}_FETCH_REQUESTED` });

      return axios
        .get(`${root}/${entityId}`, { headers })
        .then(({ data }) => {
          dispatch({
            type: `${actionPrefix}_FETCH_SUCCEEDED`,
            payload: { [data.id]: data },
          });
          return data;
        })
        .catch((error) => {
          dispatch({ type: `${actionPrefix}_FETCH_FAILED` });
          return Promise.reject(error);
        });
    };
  };

  const saveEntity = (entity) => {
    return (dispatch, getState) => {
      const headers = addAuthHeaders({}, getState, authenticated);

      dispatch({ type: `${actionPrefix}_SAVE_REQUESTED` });

      return axios
        .put(`${root}/${entity.id}`, entity, { headers })
        .then(({ data }) => {
          dispatch({ type: `${actionPrefix}_SAVE_SUCCEEDED`, payload: data });
          return data;
        })
        .catch((error) => {
          dispatch({ type: `${actionPrefix}_SAVE_FAILED` });
          return Promise.reject(error);
        });
    };
  };

  const createEntity = (entity) => {
    return (dispatch, getState) => {
      const headers = addAuthHeaders({}, getState, authenticated);

      dispatch({ type: `${actionPrefix}_SAVE_REQUESTED` });

      return axios
        .post(`${root}`, entity, { headers })
        .then(({ data }) => {
          dispatch({ type: `${actionPrefix}_SAVE_SUCCEEDED`, payload: data });
          return data;
        })
        .catch((error) => {
          dispatch({ type: `${actionPrefix}_SAVE_FAILED` });
          return Promise.reject(error);
        });
    };
  };

  const patchEntity = (entity) => {
    return (dispatch, getState) => {
      const headers = addAuthHeaders({}, getState, authenticated);

      dispatch({ type: `${actionPrefix}_PATCH_REQUESTED` });

      return axios
        .patch(`${root}/${entity.id}`, entity, { headers })
        .then(({ data }) => {
          dispatch({ type: `${actionPrefix}_PATCH_SUCCEEDED`, payload: data });
          return data;
        })
        .catch((error) => {
          dispatch({ type: `${actionPrefix}_PATCH_FAILED` });
          return Promise.reject(error);
        });
    };
  };

  const deleteEntity = (id) => {
    return (dispatch, getState) => {
      const headers = addAuthHeaders({}, getState, authenticated);

      dispatch({ type: `${actionPrefix}_DELETE_REQUESTED` });

      return axios
        .delete(`${root}/${id}`, { headers })
        .then(({ data }) => {
          dispatch({ type: `${actionPrefix}_DELETE_SUCCEEDED`, payload: id });
          return data;
        })
        .catch((error) => {
          dispatch({ type: `${actionPrefix}_DELETE_FAILED` });
          return Promise.reject(error);
        });
    };
  };

  const fetchEntities = (params = {}) => {
    return (dispatch, getState) => {
      const headers = addAuthHeaders({}, getState, authenticated);

      dispatch({ type: `${actionPrefix}_FETCH_REQUESTED` });

      return axios
        .get(root, {
          params: params,
          headers,
        })
        .then(({ data }) => {
          dispatch({
            type: `${actionPrefix}_FETCH_SUCCEEDED`,
            payload: data.reduce((acc, plan) => {
              acc[plan.id] = plan;
              return acc;
            }, {}),
          });

          return data;
        })
        .catch((error) => {
          dispatch({ type: `${actionPrefix}_FETCH_FAILED`, payload: error });
          return Promise.reject(error);
        });
    };
  };

  return {
    fetchEntity,
    saveEntity,
    createEntity,
    patchEntity,
    deleteEntity,
    fetchEntities,
  };
};
