export const beforeSend = (event, hint) => {
  if (typeof window !== 'undefined') {
    const isIE = /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/.test(
      window.navigator.userAgent
    );
    if (
      isIE ||
      !window.URL ||
      !window.Proxy ||
      !window.fetch ||
      !window.Map ||
      !window.BigInt // Using this as a proxy for old browsers, dont need bigingt support
    ) {
      return null;
    }
  }

  // Ignore gsi/client errors
  if (
    event?.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename?.includes?.(
      'gsi/client'
    )
  ) {
    return null;
  }

  // Recapcha errors
  if (hint.originalException === 'Timeout') {
    return null;
  }

  // Anaxios requests already logged
  if (hint?.originalException?.request) {
    return null;
  }

  return event;
};

export const maxValueLength = 2000;

export const ignoreErrors = [
  /^timeout of 0ms exceeded$/,
  /IDBFactory\.open\(\) called in an invalid security context/,
  /Loading chunk /,
  /Loading CSS chunk/,
  /Failed to update a ServiceWorker: An unknown error occurred when fetching the script/,
  /The Internet connection appears to be offline/,
  /Failed to update a ServiceWorker/,
  /Undefined variable: WebSocket/,
  /lastpass/i,
  /Request aborted/,
  /ResizeObserver loop/,
  /__gCrWeb/, // Chrome IOS iFrame bug?
  /SecurityError: Failed to read the 'localStorage' property from 'Window'/, // iFrame bug
  /AbortError: The operation was aborted./,
  /sw\.js load failed/,
  /^'fetch' is undefined^/,
  /^Network Error$/, // No internet connection axios
  /^Failed to fetch$/, // No internet connection fetch
  /^The operation couldn’t be completed. Software caused connection abort$/,
  /^NetworkError when attempting to fetch resource.$/, // Firefox
  /^createError/, // Anaxios
  /^generatorResume/, // Anaxios promise rejection?
  /jquery/i, // embeds...
  /wp-includes/, // Wordpress
  /wp-content/, // Wordpress
  /elementor/i, // Wordpress
  /unrecognized expression: #\//, // Wordpress
  /^NotSupportedError: Failed to get ServiceWorkerRegistration objects/,
  /^Can't find variable: logMutedMessage/,
  /^Error during service worker registration/,
  /gsi\/client/, // Google Identity Services
];

export const denyUrls = [
  // Google login
  /gsi\/client/i,
];
