import React from 'react';
import { Box, BoxProps } from '../grid';

export const trimTextNode = ({ lineHeight }: { lineHeight: number }) => {
  const descenderHeightScale = 0.13;
  const capHeightScale = 0.77;

  const typeOffset = (lineHeight - 1) / 2 + descenderHeightScale;
  const heightCorrection = lineHeight - capHeightScale;
  const preventCollapse = 1;

  return {
    paddingTop: `${preventCollapse}px`,
    /* baseline on bottom of container */
    transform: `translateY(${typeOffset}em)`,
    /* crop the top of the text node */
    '&:before': {
      content: '""',
      marginTop: `calc(${-heightCorrection}em - ${preventCollapse}px)`,
      display: 'block',
      height: 0,
    },
  };
};

export const CroppedText = React.forwardRef<HTMLDivElement, BoxProps>(
  function CroppedText(props, ref) {
    return (
      <Box
        ref={ref as any}
        __css={{
          fontFamily: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'body',
          fontSize: 2,
          ...trimTextNode({ lineHeight: Number(props.lineHeight) || 1.6 }),
        }}
        {...props}
      />
    );
  }
);

export const Text = React.forwardRef<HTMLDivElement, BoxProps>(function Text(
  props,
  ref
) {
  return (
    <Box
      ref={ref as any}
      __css={{
        fontFamily: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'body',
        fontSize: 2,
        wordBreak: 'break-word',
      }}
      {...props}
    />
  );
});

export const Heading = React.forwardRef<HTMLDivElement, BoxProps>(
  function Heading(props, ref) {
    return (
      <Box
        ref={ref as any}
        as="h2"
        tx="text"
        variant="heading"
        {...props}
        __css={{
          fontSize: 4,
          fontFamily: 'heading',
          fontWeight: 'heading',
          lineHeight: 'heading',
          color: 'inherit',
        }}
      />
    );
  }
);

export const Link = React.forwardRef<HTMLDivElement, BoxProps>(function Link(
  props,
  ref
) {
  return (
    <Box
      ref={ref as any}
      as="a"
      tx="text"
      variant="link"
      __css={{
        fontFamily: 'inherit',
        fontWeight: 'heading',
        fontSize: 'inherit',
        lineHeight: 'body',
        display: 'inline',
        textAlign: 'inherit',
      }}
      {...props}
    />
  );
});

export const Anchor = Link;
