import { IN_READ_ONLY_MODE } from './utils/constants';
import queryString, { StringifyOptions } from 'query-string';

const stringifyOptions: StringifyOptions = {
  arrayFormat: 'comma',
};

export interface Options {
  url?: string; // the URL to request
  method?: // | 'get'
  // | 'post'
  // | 'put'
  // | 'patch'
  // | 'delete'
  // | 'options'
  // | 'head'
  'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD'; // HTTP method, case-insensitive
  headers?: Headers; // Request headers
  body?: FormData | string | Record<string, any>; // a body, optionally encoded, to send
  responseType?:
    | 'text'
    | 'json'
    | 'stream'
    | 'blob'
    | 'arrayBuffer'
    | 'formData'
    | 'stream'; // An encoding to use for the response
  params?: Record<string, any> | URLSearchParams; // querystring parameters
  paramsSerializer?: (params: Options['params']) => string; //querystring parameters custom function to stringify querystring parameters
  withCredentials?: boolean; // Send the request with credentials like cookies
  auth?: string; // Authorization header value to send with the request
  xsrfCookieName?: string; // Pass an Cross-site Request Forgery prevention cookie value as a header defined by `xsrfHeaderName`
  xsrfHeaderName?: string; // The name of a header to use for passing XSRF cookies
  validateStatus?: (status: number) => boolean; // Override status code handling (default: 200-399 is a success)
  transformRequest?: Array<(body: any, headers: Headers) => any>; // An array of transformations to apply to the outgoing request
  transformResponse?: Array<(data: any) => any>; // An array of transformations to apply to response data
  baseURL?: string; // a base URL from which to resolve all URLs
  cancelToken?: AbortSignal; // signal returned by AbortController
  mode?: RequestMode; // The mode of the request (e.g., cors, no-cors, same-origin, or navigate.). Defaults to cors.
  data?: any;
  skipErrorResponseIntercept?: boolean;
  skipErrorReportOnCode?: Record<string, boolean>;
}

interface Headers {
  [name: string]: string;
}

export interface Response<T> {
  status: number;
  statusText: string;
  config: Options; // the request configuration
  data: T; // the decoded response body
  headers: Headers;
  redirect: boolean;
  url: string;
  type: ResponseType;
  body: ReadableStream<Uint8Array> | null;
  bodyUsed: boolean;
}

export default (function create(defaults?: Options) {
  defaults = defaults || {};

  anaxios.request = anaxios;

  anaxios.get = <T = any>(url: string, config?: Options) =>
    anaxios<T>(url, config, 'GET');

  anaxios.delete = <T = any>(url: string, config?: Options) =>
    anaxios<T>(url, config, 'DELETE');

  anaxios.head = <T = any>(url: string, config?: Options) =>
    anaxios<T>(url, config, 'HEAD');

  anaxios.options = <T = any>(url: string, config?: Options) =>
    anaxios<T>(url, config, 'OPTIONS');

  anaxios.post = <T = any>(url: string, data?: any, config?: Options) =>
    anaxios<T>(url, config, 'POST', data);

  anaxios.put = <T = any>(url: string, data?: any, config?: Options) =>
    anaxios<T>(url, config, 'PUT', data);

  anaxios.patch = <T = any>(url: string, data?: any, config?: Options) =>
    anaxios<T>(url, config, 'PATCH', data);

  anaxios.interceptors = {
    request: new Interceptor(),
    response: new Interceptor(),
  };

  function deepMerge(
    opts: Record<string, any>,
    overrides?: Record<string, any>,
    lowerCase?: boolean
  ): Partial<typeof opts> {
    const out = {};
    let i;
    if (Array.isArray(opts)) {
      return opts.concat(overrides);
    }
    for (i in opts) {
      const key = lowerCase ? i.toLowerCase() : i;
      out[key] = opts[i];
    }
    for (i in overrides) {
      const key = lowerCase ? i.toLowerCase() : i;
      const value = overrides[i];
      out[key] =
        key in out && typeof value == 'object'
          ? deepMerge(out[key], value, key == 'headers')
          : value;
    }
    return out;
  }

  function CancelToken(executor: (any) => any): AbortSignal {
    if (typeof executor !== 'function') {
      throw new TypeError('executor must be a function.');
    }

    const ac = new AbortController();

    executor(ac.abort.bind(ac));

    return ac.signal;
  }

  CancelToken.source = () => {
    const ac = new AbortController();

    return {
      token: ac.signal,
      cancel: ac.abort.bind(ac),
    };
  };

  function createError(message, config, response, request) {
    const error = new Error(message);
    error['config'] = config;
    error['response'] = response;
    error['request'] = request;

    return error;
  }

  function anaxios<T = any>(
    url: string | Options,
    config?: Options,
    _method?: Options['method'],
    _data?: any
  ): Promise<Response<T>> {
    if (typeof url != 'string') {
      config = url;
      url = config.url;
    }

    let response: Response<T> = { config } as unknown as Response<T>;

    let options: Options = deepMerge(defaults, config);

    if (_data) options.data = _data;

    anaxios.interceptors.request.handlers.map((handler) => {
      if (handler) {
        const resultConfig = handler[0](options);
        options = deepMerge(options, resultConfig || {});
      }
    });

    const customHeaders: Headers = {};

    let data = (options.transformRequest || []).reduce((data, f) => {
      return f(data, options.headers) || data;
    }, options.data);

    if (data && typeof data == 'object' && typeof data.append != 'function') {
      data = JSON.stringify(data);
      customHeaders['content-type'] = 'application/json';
    }

    const m =
      typeof document !== 'undefined' &&
      document.cookie.match(
        RegExp('(^|; )' + options.xsrfCookieName + '=([^;]*)')
      );
    if (m) customHeaders[options.xsrfHeaderName] = m[2];

    if (options.auth) {
      customHeaders.authorization = options.auth;
    }

    if (options.baseURL) {
      url = url.replace(/^(?!.*\/\/)\/?(.*)$/, options.baseURL + '/$1');
    }

    if (options.params) {
      const divider = ~url.indexOf('?') ? '&' : '?';

      const sanitizedParams = Object.fromEntries(
        Object.entries(options.params)
          .filter(([k, v]) => v !== undefined && v !== null)
          .map(([k, v]) => [
            k,
            typeof v == 'object' && !Array.isArray(v) ? JSON.stringify(v) : v,
          ])
      );
      const query = queryString.stringify(sanitizedParams, stringifyOptions);

      if (query) {
        url += divider + query;
      }
    }

    const method = _method || options.method;

    if (
      IN_READ_ONLY_MODE &&
      ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase()) &&
      !url.match(/\/token/) &&
      !(url.match(/\/api\/user/) && method.toUpperCase() === 'POST')
    ) {
      alert('Bookem is under maintenance, only read operations are allowed');
      return Promise.reject(
        new Error(
          `Anaxios: ${method.toUpperCase()} is not allowed in read-only mode.`
        )
      );
    }

    const request = new Request(url, {
      method: method,
      body: data,
      headers: deepMerge(options.headers, customHeaders, true),
      credentials: options.withCredentials ? 'include' : 'same-origin',
      signal: options.cancelToken,
      mode: options.mode ? options.mode : 'cors',
    });

    return fetch(request)
      .then(
        async (res) => {
          for (const i in res) {
            if (typeof res[i] != 'function') {
              response[i] = res[i];
            }
          }

          const ok = options.validateStatus
            ? options.validateStatus(res.status)
            : res.ok;

          if (!ok) {
            const path = new URL(request.url).pathname.replace(
              /[0-9a-f]{32}/,
              '{UUID}'
            );

            const error = createError(
              `${request.method} request to ${path} with status code ${
                response.status
              } ${response.statusText || ''}`,
              options,
              response,
              request
            );

            response.data = (await res.text()) as any;

            if (!config?.skipErrorResponseIntercept) {
              anaxios.interceptors.response.handlers.map((handler) => {
                if (handler && handler[1]) {
                  handler[1](error);
                }
              });
            }

            anaxios.interceptors.request.handlers.map((handler) => {
              if (handler && handler[1]) {
                handler[1](response);
              }
            });

            throw error;
          }

          if (!res[options.responseType || 'text']) {
            return res.body;
          }

          const raw = res[options.responseType || 'text']().catch(
            () => undefined
          );
          return raw.then(JSON.parse).catch(() => raw);
        },
        (e) => {
          // Avoid all the different types of errors
          // console.warn(e);

          const status = 0;
          const statusText = 'Network Error';

          const path = new URL(request.url).pathname.replace(
            /[0-9a-f]{32}/,
            '{UUID}'
          );

          const error = createError(
            `${request.method} request to ${path} with status code ${status} ${statusText}`,
            options,
            { status: 0, statusText: 'Network Error' },
            request
          );

          // When navigating away from a page an cancellation happens and triggers a get
          // we don't care about gets that are cancelled
          if (!config?.skipErrorResponseIntercept && method !== 'GET') {
            anaxios.interceptors.response.handlers.map((handler) => {
              if (handler && handler[1]) {
                handler[1](error);
              }
            });
          }

          throw error;
          // throw new Error('Failed to fetch');
        }
      )
      .then((data) => {
        response.data = (options.transformResponse || []).reduce(
          (data, f) => f(data) || data,
          data
        );

        anaxios.interceptors.response.handlers.map((handler) => {
          response = (handler && handler[0](response)) || response;
        });

        return response;
      });
  }

  anaxios.CancelToken = CancelToken;
  anaxios.isCancel = (e): boolean => e.name === 'AbortError';
  anaxios.defaults = defaults as Options;
  anaxios.create = create;

  return anaxios;
})();

function Interceptor() {
  this.handlers = [] as [done: (arg: any) => any, error: (arg: any) => any][];

  this.use = function (
    done: (arg: any) => any,
    error: (arg: any) => any
  ): number {
    return this.handlers.push([done, error]) - 1;
  };

  this.eject = function (id: number) {
    this.handlers[id] = null;
  };
}
