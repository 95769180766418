import { FSA } from 'flux-standard-action';
import {
  IP_LOCATION_FETCH_REQUESTED,
  IP_LOCATION_FETCH_SUCCEEDED,
  IP_LOCATION_FETCH_FAILED,
} from './action-types';

const initialState = {
  status: 'done',
  // "ip": "196.215.12.144",
  country_code: 'ZA',
  country_name: 'South Africa',
  region_code: 'WC',
  region_name: 'Western Cape',
  city: 'Cape Town',
  zip_code: '8000',
  time_zone: 'Africa/Johannesburg',
  latitude: -33.9161,
  longitude: 18.4139,
  metro_code: 0,
};

export default function reducer(state = initialState, action: FSA<any, any>) {
  const { type, payload } = action;
  switch (type) {
    case IP_LOCATION_FETCH_REQUESTED:
      return { ...state, status: 'loading' };
    case IP_LOCATION_FETCH_SUCCEEDED:
      return { ...state, status: 'done', ...payload };
    case IP_LOCATION_FETCH_FAILED:
      return { ...state, status: 'failed' };
    default:
      return state;
  }
}
