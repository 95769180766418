import { DateTime, Settings as LuxonSettings } from 'luxon';
import { RRule } from 'rrule';
import { ScheduleItem } from './booking-tools';

export interface ExternalBusy {
  id: string;
  start: string;
  end: string;
  bookable_id: string;
  business_id: string;
  location_id?: string; // For public bookings use as busy
  description: string;
  booking_id: string;
}

export interface ScheduleOverride {
  id: string;
  notes: string;
  status: 'blocked';
  start: string;
  end: string;
  business_id: string;
  location_id: string;
  bookable_id: string;
}

export interface BookingService {
  start: string;
  end: string;
  service_id: string | null;
  service_name?: string;
  bookable_ids?: string[];
}

export type ExtendedBookingService = BookingService & {
  booking: Booking;
  serviceIdx: number;
  serviceName: string;
  bookableNames: string[];
};

export interface Booking {
  id?: string;
  created_at: string;
  business_id: string;
  location_id: string;
  invoice_ids: string[];
  services: BookingService[];
  status?:
    | 'confirmed'
    | 'requested'
    | 'denied'
    | 'cancelled'
    | 'no_show'
    | 'pencilled_in'
    | 'awaiting_payment';
  notes: string;
  client_ids?: string[];
  dont_send_notifications?: boolean;
  client_timezone?: string;
  third_party_video_call_url?: string;
  video_call_enabled?: boolean;
  promo_code_id?: string;
  recurrence_rule?: string;
  recurrence_start_booking_id?: string;
  recurrence_index?: number;
  timezone?: string;
  notify?: boolean;
  affects?: 'this' | 'future' | 'following';
}

export interface ScheduleSubject {
  service_id: string;
  bookable_id: string;
  location_id: string;
}

export class Schedule {
  id: string;
  business_id: string;
  start: string;
  end: string;
  type: 'available' | 'unavailable';
  description: string;
  include: ScheduleSubject[];
  exclude: ScheduleSubject[];
  rrule: string;
}

const legacyTzMap = {
  CST6CDT: 'America/Chicago',
  EST5EDT: 'America/New_York',
  MST7MDT: 'America/Denver',
  PST8PDT: 'America/Los_Angeles',
  'Etc/GMT0': 'Etc/GMT-0',
  'GMT+0': 'Etc/GMT-0',
  'GMT-0': 'Etc/GMT-0',
  GMT0: 'Etc/GMT-0',
};

export const getBrowserTimezone = () => {
  // For debugging
  if (typeof window !== 'undefined') {
    window['DateTime'] = DateTime;
    window['LuxonSettings'] = LuxonSettings;
  }

  let tz = DateTime.local().zoneName;
  const failedToDetectTz = !tz; // https://www.reddit.com/r/MacOSBeta/comments/16vtp6c/intldatetimeformatresolvedoptionstimezone/
  const offset = new Date().getTimezoneOffset() / 60;

  if (!tz || !DateTime.utc().setZone(tz).isValid) {
    tz = legacyTzMap[tz];
    if (!tz) {
      tz = `Etc/GMT${offset < 0 ? '-' : '+'}${Math.abs(offset)}`;

      if (!DateTime.utc().setZone(tz).isValid) {
        throw Error(`Bad timezone: ${tz}`);
      }
    }
  }

  return failedToDetectTz && tz === 'Etc/GMT-2' ? 'Africa/Johannesburg' : tz;
};

export const timeStrToHours = (t) =>
  t
    .split(':')
    .map(Number)
    .map((v, idx) => (idx === 0 ? v : v / 60))
    .reduce((agg, cur) => agg + cur, 0);

export const hoursToTimeStr = (h) =>
  `${String(h | 0).padStart(2, '0')}:${String(
    Math.round((h % 1) * 60) | 0
  ).padStart(2, '0')}`;

export const DAY_IN_MILLISECONDS = 86400000;

// Open does not include endpoints

export function intersects<T>(a: T[], b: T[], open1 = true, open2 = true) {
  if (!open1 && a[1] < b[0]) {
    return false;
  }

  if (!open2 && b[1] < a[0]) {
    return false;
  }

  if (open1 && a[1] <= b[0]) {
    return false;
  }

  if (open2 && b[1] <= a[0]) {
    return false;
  }

  return true;
}

export function subtract<T>(p1: T, p2: T) {
  // subtract p1 from p2
  const ret = [];

  // update this code in python
  if (p1[0] < p2[0] && p2[0] < p1[1]) {
    if (p1[0] !== p2[0]) {
      ret.push([p1[0], p2[0]]);
    }
    if (p2[1] < p1[1]) {
      ret.push([p2[1], p1[1]]);
    }
  } else if (p2[0] <= p1[0] && p1[0] <= p2[1]) {
    if (p2[1] < p1[1]) {
      ret.push([p2[1], p1[1]]);
    }
  } else {
    ret.push(p1);
  }

  return ret;
}

export function intersect<T>(a: T[], b: T[]) {
  if (a[1] < b[0] || b[1] < a[0]) {
    // no intersectioon
    return [];
  }

  let start;
  let end;

  if (a[0] <= b[0]) {
    start = b[0];
  } else if (b[0] <= a[0]) {
    start = a[0];
  }
  if (a[1] <= b[1]) {
    end = a[1];
  } else if (b[1] <= a[1]) {
    end = b[1];
  }

  return [start, end];
}

export function intervalSortFn<T>(a: T, b: T) {
  if (a[0] < b[0]) {
    return -1;
  } else if (a[0] > b[0]) {
    return 1;
  }
  return 0;
}

export function subtractIntervals<T>(intervals: T[][], nonintervals: T[][]) {
  if (!nonintervals) {
    return intervals;
  }

  intervals.sort(intervalSortFn);
  nonintervals.sort(intervalSortFn);

  let newIntervals = [];

  for (let n = 0; n < nonintervals.length; n++) {
    const ns = nonintervals[n];
    for (let s = 0; s < intervals.length; s++) {
      const sl = intervals[s];
      if (ns[0] > sl[1]) {
        newIntervals.push(sl);
        continue;
      } else if (ns[1] < sl[0]) {
        newIntervals.push(sl);
        continue;
      }
      Array.prototype.push.apply(newIntervals, subtract(sl, ns));
    }
    intervals = newIntervals;
    newIntervals = [];
  }

  return intervals;
}

export function expandRule(
  fromUTC: string,
  untilUTC: string,
  timezone: string,
  schedule: Schedule
): {
  start: string;
  end: string;
  include: ScheduleSubject;
  exclude: ScheduleSubject;
  rule: Schedule;
}[] {
  let occurrences;

  // Beware of code trash fire
  // -----------------------------
  // RRule treats rules as occuring in UTC
  // So we interpret the local time to UTC
  // Then we do the rrule, then we interpret it as local time again
  // Then we convert it back to actual UTC

  const localFrom = DateTime.fromISO(fromUTC, { zone: 'UTC' })
    .setZone(timezone)
    .toISO()
    .substring(0, 19);
  const localUntil = DateTime.fromISO(untilUTC, { zone: 'UTC' })
    .setZone(timezone)
    .toISO()
    .substring(0, 19);

  const scheduleStart = DateTime.fromISO(schedule.start, { zone: timezone });
  const scheduleEnd = DateTime.fromISO(schedule.end, { zone: timezone });
  const duration = scheduleEnd.valueOf() - scheduleStart.valueOf();

  if (duration < 1) {
    occurrences = [];
  } else if (schedule.rrule) {
    const rule = RRule.parseString(schedule.rrule);
    rule.wkst = RRule.MO;
    // TODO: review this
    rule.dtstart = DateTime.fromISO(schedule.start, { zone: 'UTC' }).toJSDate();
    const rrule = new RRule(rule);
    occurrences = rrule
      .between(
        DateTime.fromISO(localFrom, { zone: 'UTC' })
          .minus({ milliseconds: duration })
          .toJSDate(),
        DateTime.fromISO(localUntil, { zone: 'UTC' }).toJSDate(),
        true
      )
      .map((date) =>
        DateTime.fromJSDate(date, { zone: 'UTC' })
          .setZone(timezone, { keepLocalTime: true })
          .toUTC()
      );
  } else if (
    intersects(
      [
        scheduleStart.toUTC().toISO().substring(0, 19) + 'Z',
        scheduleEnd.toUTC().toISO().substring(0, 19) + 'Z',
      ],
      [fromUTC, untilUTC]
    )
  ) {
    occurrences = [scheduleStart.toUTC()];
  } else {
    occurrences = [];
  }

  return occurrences
    .map((o) => ({
      start: o.toISO().substring(0, 19) + 'Z',
      end: o.plus({ milliseconds: duration }).toISO().substring(0, 19) + 'Z',
      include: schedule.include,
      exclude: schedule.exclude,
      rule: schedule,
    }))
    .reduce((agg, cur, idx) => {
      // Join overlapping occurrences
      if (idx === 0) {
        agg.push(cur);
      } else {
        const last = agg[agg.length - 1];
        if (intersects([cur.start, cur.end], [last.start, last.end])) {
          last.end = cur.end;
        } else {
          agg.push(cur);
        }
      }

      return agg;
    }, []);
}

export type BookableAvailability = Record<
  string,
  {
    available: boolean;
    bookings: string;
    busy: boolean;
  }
>;

export function getBookableAvailability(
  start: string,
  end: string,
  matches: ScheduleItem[],
  bookingWindow,
  serviceId = '*'
): BookableAvailability {
  const bookableAvailability = {};

  for (const m of matches) {
    const match = m[2];
    const interval = [m[0], m[1]];
    const type = match.type;
    const bookableIds = match.bookableIds;

    // if (!match.bookableIds.length === 1) {
    //   throw new Exception('Assumption that bookableIds has length 1 failed');
    // }

    // Skip matches that does not apply to this service
    if (serviceId !== '*' && match.serviceId && match.serviceId !== serviceId) {
      continue;
    }

    bookableIds.forEach((bookableId) => {
      const ba = (bookableAvailability[bookableId] = bookableAvailability[
        bookableId
      ] || {
        available: false,
        busy: false,
        bookings: 0,
      });

      if (!ba.available && type === 'available') {
        ba.available = start >= interval[0] && end <= interval[1];
      }

      if (type === 'unavailable' || type === 'booking' || type === 'busy') {
        const intersectsSlot = intersects(interval, [start, end]);

        if (!ba.busy && intersectsSlot) {
          ba.busy = intersectsSlot;
        }

        if (type === 'booking' && intersectsSlot) {
          ba.bookings++;
        }
      }

      // Outside booking window
      if (
        bookingWindow &&
        ba.available &&
        (start < bookingWindow[0] || end > bookingWindow[1])
      ) {
        ba.available = false;
      }
    });
  }

  return bookableAvailability;
}

export function getCalendarPageRange2(dt: string) {
  const date = DateTime.fromISO(dt, { zone: 'UTC' });

  const monthStart = date.startOf('month').startOf('day');
  const monthEnd = date.endOf('month').endOf('day');

  const firstDayOfMonthIdx = monthStart.weekday - 1;
  const lastDayOfMonthIdx = monthEnd.weekday - 1;

  const calendarStart = monthStart.plus({ days: -firstDayOfMonthIdx });
  const calendarEnd = monthEnd.plus({ days: 6 - lastDayOfMonthIdx });

  return [
    calendarStart.toISO().substring(0, 19) + 'Z',
    calendarEnd.toISO().substring(0, 19) + 'Z',
  ];
}

interface CalendarDay {
  day: number;
  currentMonth: boolean;
  inPast: boolean;
  date: string;
  current: boolean;
}

export function getCalendarPage(dt: string, timezone: string) {
  const datetime = DateTime.fromISO(dt).setZone(timezone);

  const calendarDateStart = datetime.startOf('month').startOf('week');
  const calendarDateEnd = datetime.endOf('month').endOf('week');

  const lastDayIdx =
    (calendarDateEnd.toMillis() - calendarDateStart.toMillis()) /
    DAY_IN_MILLISECONDS;
  const today = DateTime.utc().setZone(timezone).startOf('day');

  const selectedMonth = datetime.startOf('month');
  const calendarWeeks: CalendarDay[][] = [];
  const calendarDays: CalendarDay[] = [];
  let currentDayIdx = 0;

  while (currentDayIdx <= lastDayIdx) {
    if (currentDayIdx % 7 === 0) {
      calendarWeeks.push([]);
    }
    const date = calendarDateStart.plus({ day: currentDayIdx });

    const data = {
      day: date.day,
      currentMonth: date.hasSame(selectedMonth, 'month'),
      inPast: date < today,
      date: date.toISO().substring(0, 10),
      current: date.hasSame(today, 'day'),
    };
    calendarWeeks[Math.floor(currentDayIdx / 7)].push(data);
    calendarDays.push(data);
    currentDayIdx++;
  }

  return {
    weeks: calendarWeeks,
    days: calendarDays,
  };
}

export function getCalendarPage2(dt: string) {
  const [calendarDateStart, calendarDateEnd] = getCalendarPageRange2(dt);
  const start = DateTime.fromISO(calendarDateStart, { zone: 'UTC' });
  const end = DateTime.fromISO(calendarDateEnd, { zone: 'UTC' });

  const lastDayIdx = (end.valueOf() - start.valueOf()) / DAY_IN_MILLISECONDS;

  const calendarWeeks = [];
  const calendarDays = [];
  let currentDayIdx = 0;

  while (currentDayIdx <= lastDayIdx) {
    if (currentDayIdx % 7 === 0) {
      calendarWeeks.push([]);
    }

    const date = start.plus({ days: currentDayIdx });

    const data = {
      date: date.toISO().substring(0, 19) + 'Z',
    };

    calendarWeeks[Math.floor(currentDayIdx / 7)].push(data);
    calendarDays.push(data);
    currentDayIdx++;
  }

  return {
    weeks: calendarWeeks,
    days: calendarDays,
  };
}

// Slot ops

export function unionIntervals(...args) {
  const intervals = [].concat(...args).sort(intervalSortFn);

  return intervals.reduce((acc, interval) => {
    if (acc.length === 0) {
      return [interval];
    }
    const a = acc[acc.length - 1];
    const b = interval;
    // equal or overlap
    if (a[1] === b[0]) {
      acc[acc.length - 1] = [a[0], b[1]];
    } else if (b[0] < a[1]) {
      acc[acc.length - 1] = [a[0], b[1] > a[1] ? b[1] : a[1]];
    } else {
      acc.push(interval);
    }
    return acc;
  }, []);
}

export function intersectIntervals<T>(intervals1: T[][], intervals2: T[][]) {
  const intersections = [];
  for (let i = 0; i < intervals1.length; i++) {
    for (let j = 0; j < intervals2.length; j++) {
      const intersection = intersect(intervals1[i], intervals2[j]);

      if (intersection.length === 2) {
        intersections.push(intersection);
      }
    }
  }
  return intersections;
}

export function firstIntesection<T>(start: T, end: T, intervals: T[][]) {
  const interval = [start, end];
  for (let i = 0; i < intervals.length; i++) {
    const intersection = intersect(interval, intervals[i]);

    if (intersection.length === 2) {
      return intersection;
    }
  }

  return [start, start];
}

export function isContained<T>(start: T, end: T, intervals: T[][]) {
  let contained = false;
  for (let i = 0; i < intervals.length; i++) {
    if (start >= intervals[i][0] && end <= intervals[i][1]) {
      contained = true;
      break;
    }
  }
  return contained;
}
