import { storageFactory } from './storage-factory';
const local = storageFactory(() => localStorage);

const DEV_DOMAIN = 'test.backflip.co.za';
const STAGING_DOMAIN = 'backflip.co.za';
const PRODUCTION_DOMAINS = [
  'bookem.com',
  'bookem.co.za',
  'bookem.ch',
  'bookem.in',
  'bookem.us',
  'bookem.org',
  'bookem.co',
  'bookem.xyz',
  'bookem.app',
  'bkm.app',
];

const isBrowser = typeof window !== 'undefined';

const IP_REGEX =
  /^(([1-9]?\d|1\d\d|2[0-5][0-5]|2[0-4]\d)\.){3}([1-9]?\d|1\d\d|2[0-5][0-5]|2[0-4]\d)$/;

const defaultHostname = {
  production: PRODUCTION_DOMAINS[0],
  staging: STAGING_DOMAIN,
  dev: DEV_DOMAIN,
}[__TARGET_ENV__];

function getBookemBaseDomain() {
  const hostname = isBrowser ? window.location.hostname : defaultHostname;

  if (hostname.match(IP_REGEX) || __TARGET_ENV__ === 'dev') {
    return defaultHostname;
  }

  if (hostname.includes(DEV_DOMAIN)) {
    return hostname;
  }

  const parts = hostname.split('.');
  const len = parts.length;

  if (['localhost', 'test'].includes(parts[parts.length - 1])) {
    return hostname;
  }

  if (len < 2) {
    return hostname;
  }

  let pos = len - 2;

  if (parts[len - 2].length <= 2) {
    pos = len - 3;
  }
  return parts.splice(pos).join('.');
}

const bookemBaseDomain = getBookemBaseDomain();
const customPublicRoot = local.getItem('publicRoot');
const apiPublicPath = customPublicRoot ? customPublicRoot : __PUBLIC_PATH__;

const isSecure = isBrowser
  ? window.location.protocol === 'https:'
  : __TARGET_ENV__ !== 'dev';

// const websocketUrl = {
//   production: 'wss://websocket.bkm.app/ws', // Bypass cloudflare on production
//   staging: 'wss://backflip.co.za/ws',
//   dev: `${isSecure ? 'wss' : 'ws'}://test.backflip.co.za/ws`,
//   // dev: 'wss://websocket.bkm.app/ws',
// }[__TARGET_ENV__];

const websocketUrl = {
  production: `wss://${isBrowser ? window.location.host : 'bkm.app'}/ws`,
  staging: `wss://${isBrowser ? window.location.host : 'backflip.co.za'}/ws`,
  dev: `${isSecure ? 'wss' : 'ws'}://${
    isBrowser ? window.location.host : 'test.backflip.co.za'
  }/ws`,
  // dev: 'wss://websocket.bkm.app/ws',
}[__TARGET_ENV__];

// const signallingWebsocketUrl = {
//   production: 'wss://websocket.bkm.app/signalling', // Bypass cloudflare on production
//   staging: 'wss://backflip.co.za/signalling',
//   dev: `${isSecure ? 'wss' : 'ws'}://test.backflip.co.za/signalling`,
//   // dev: 'wss://websocket.bkm.app/signalling',
// }[__TARGET_ENV__];

const signallingWebsocketUrl = {
  production: `wss://${
    isBrowser ? window.location.host : 'bkm.app'
  }/signalling`,
  staging: `wss://${
    isBrowser ? window.location.host : 'backflip.co.za'
  }/signalling`,
  dev: `${isSecure ? 'wss' : 'ws'}://${
    isBrowser ? window.location.host : 'test.backflip.co.za'
  }/signalling`,
  // dev: 'wss://websocket.bkm.app/signalling',
}[__TARGET_ENV__];

const protocol = __TARGET_ENV__ === 'dev' ? 'https:' : 'https:';

const port =
  isBrowser && window.location.port ? `:${window.location.port}` : '';

function getUrl(handle = '', path = '') {
  const subdomain = handle ? `${handle}.` : '';
  return `${protocol}//${subdomain}${bookemBaseDomain}${port}${path}`;
}

function getShortUrl(path = '') {
  const domain = {
    production: 'bkm.app',
    staging: STAGING_DOMAIN,
    dev: DEV_DOMAIN,
  }[__TARGET_ENV__];
  return `${protocol}//${domain}${port}${path}`;
}

function bypassPWAScope(url) {
  if (!url) {
    return url;
  } else if (url[0] === '/') {
    return getShortUrl(url);
  } else {
    return getShortUrl(new URL(url).pathname);
  }
}

// const polyfillIOFeatures = [
//   'Intl.DateTimeFormat.~locale.en',
//   'Intl.DateTimeFormat.~locale.fr',
//   'Intl.DateTimeFormat',
//   'Intl.Locale',
//   'Intl.NumberFormat.~locale.en',
//   'Intl.NumberFormat.~locale.fr',
//   'Intl.NumberFormat',
//   'Intl.PluralRules.~locale.en',
//   'Intl.PluralRules.~locale.fr',
//   'Intl.PluralRules',
//   'Intl.getCanonicalLocales',
//   'es2015',
//   'es2016',
//   'es2017',
//   'es2018',
//   'es2019',
//   'es5',
//   'es6',
//   'es7',
//   'ResizeObserver',
//   'smoothscroll',
// ];

// const polyfillUrl = `https://polyfill.io/v3/polyfill.min.js?features=${encodeURIComponent(
//   polyfillIOFeatures.join(',')
// )}`;

// console.log(polyfillUrl);

const settings = {
  getUrl,
  getShortUrl,
  bypassPWAScope,
  api1Root: apiPublicPath + '/api',
  api2Root: apiPublicPath + '/api2',
  websocketUrl,
  signallingWebsocketUrl,
  bookemBaseDomain, // not a bookem subdomain, ie bookem.co.za or bookem.ch
  domains: PRODUCTION_DOMAINS,
};

const isEmbed = !!__PUBLIC_PATH__;
const notDev = ['production', 'staging'].includes(__TARGET_ENV__);

if (isBrowser && !isEmbed && notDev) {
  const knownDomain = [STAGING_DOMAIN, ...PRODUCTION_DOMAINS].some(
    (domain) =>
      window.location.hostname.endsWith(domain) ||
      window.location.hostname.endsWith(domain + '.')
  );

  if (
    notDev &&
    !knownDomain &&
    !window.location.hostname.startsWith('file://')
  ) {
    setTimeout(() => {
      if (window['Sentry']) {
        console.log({
          TARGET_ENV: __TARGET_ENV__,
          PUBLIC_PATH: __PUBLIC_PATH__,
        });
        Sentry.captureMessage(
          `Suspected phishing attempt at ${window.location.hostname}`,
          'error'
        );
      }
    });

    // TODO: fuck up all the settings in a non obvious way if not on a bookem domain
    // settings = {
    //   getUrl: () => 'bookem',
    //   getShortUrl: () => 'bkm',
    //   bypassPWAScope: () => 'kbk',
    //   api1Root: 'https://google.com/api',
    //   api2Root: 'https://google.com/api2',
    //   websocketUrl: 'wss://google.com/ws',
    //   bookemBaseDomain: 'google.com',
    //   domains: [],
    // };
  }
}

if (typeof window !== 'undefined') {
  window['settings'] = settings;
}

export default settings;
