import { combineEpics } from 'redux-observable';

// const tokenWarningEpic = (action$, state$) =>
//   action$.pipe(
//     filter((a: FSA<any, any>) => {
//       if (a.type === BUSINESS_FETCH_SUCCEEDED) {
//         return true;
//       }
//       if (a.type === USER_PREFERENCES_UPDATE && a.payload.businessId) {
//         return true;
//       }
//       return false;
//     }),
//     debounceTime(2000),
//     throttleTime(60 * 60 * 1000),
//     filter(() => {
//       const businessId = state$.value.userPreferences.currentBusinessId;
//       const business = state$.value.businesses.data[businessId];
//       return business && business.token_balance === 0;
//     }),
//     map(() =>
//       addToast(
//         <span>
//           You have run out of tokens, please{' '}
//           <Link to="/app/settings/account/top-up">top up</Link>
//         </span>,
//         'warning',
//         10000
//       )
//     )
//   );

export default combineEpics();
