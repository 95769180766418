import atob from 'atob';

function base64DecodeUrl(str: string) {
  return atob(str.replace(/-/g, '+').replace(/_/g, '/'));
}

export const decodeJWTPayload = (token) => {
  const [, payload] = token
    .split('.')
    .map((data: string) => base64DecodeUrl(data));
  // const { sub, exp, admin, bookings } = JSON.parse(payload);
  return JSON.parse(payload);
};
