import { ADD_TOAST, REMOVE_TOAST } from './action-types';

export const removeToast = (toast) => ({ type: REMOVE_TOAST, payload: toast });

export const addToast = (body, className = 'alert', timeout = 5000) => {
  return {
    type: ADD_TOAST,
    payload: { body, className, timeout },
  };
};
